import React, { useEffect, useMemo, useState } from "react";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import DashboardService, {
  OProfitCurrencyTable,
} from "../../../services/DashboardService";
import UtilService from "../../../services/UtilService";
import { CurrencyEnum } from "../../../models/CurrencyEnum";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";

export const ProfitCurrencyTableComponent: React.FC<{
  loaded: any;
  onlyFirstTimeShowLoading: boolean;
  refreshTime?: number;
  currency: CurrencyEnum;
}> = ({ loaded, onlyFirstTimeShowLoading, refreshTime, currency }) => {
  const { t } = useTranslation();
  let timeout: any = undefined;

  const [data, setData] = useState<{ name: string; value: string }[]>([]);

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "name",
        header: "",
      },
      {
        accessorKey: "value",
        header: "",
        Cell: ({ cell }) => (
          <Box
            sx={(theme) => ({
              display: "inline-flex",
              /*color:
                (cell.getValue() as number) > 0
                  ? "#01B636"
                  : "rgba(250, 65, 65, 0.8)",*/
            })}
          >
            <div style={{ display: "inline-flex" }}>
              <div style={{ fontWeight: "bold", fontSize: "15px" }}>
                {cell.getValue() as string}
              </div>
              <div
                style={{
                  marginLeft: "5px",
                  color:
                    parseInt(
                      (cell.getValue() as string).substring(
                        0,
                        (cell.getValue() as string).length - 2
                      )
                    ) > 0
                      ? "#01B636"
                      : "rgba(250, 65, 65, 0.8)",
                }}
              >
                {parseInt(
                  (cell.getValue() as string).substring(
                    0,
                    (cell.getValue() as string).length - 2
                  )
                ) > 0 ? (
                  <FaArrowUp />
                ) : (
                  ""
                )}
                {parseInt(
                  (cell.getValue() as string).substring(
                    0,
                    (cell.getValue() as string).length - 2
                  )
                ) < 0 ? (
                  <FaArrowDown />
                ) : (
                  ""
                )}
              </div>
            </div>
          </Box>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    let firstTimeLoaded = true;
    const dashboardService: DashboardService = new DashboardService();
    async function getData() {
      if (firstTimeLoaded || !onlyFirstTimeShowLoading) loaded(false);

      const lineValKeys: {
        key: string;
        text: string;
      }[] = [
        //{ key: "liveProfit", text: t("Live") },
        { key: "dtdProfit", text: t("Day") },
        { key: "wtdProfit", text: t("Week") },
        { key: "mtdProfit", text: t("Month") },
      ];

      for (let i = 0; i < lineValKeys.length; i++) {
        lineValKeys[i].key =
          lineValKeys[i].key + UtilService.getCurrencyToKeyPart(currency);
      }

      try {
        let _data = await dashboardService.getProfitCurrency();
        let newData: {
          name: string;
          value: string;
        }[] = [];

        for (let i = 0; i < lineValKeys.length; i++) {
          const lineValKey = lineValKeys[i];

          newData.push({
            name: lineValKey.text,
            value:
              parseFloat(
                String(_data[lineValKey.key as keyof OProfitCurrencyTable])
              ).toFixed(2) +
              " " +
              currency.toString(),
          });
        }

        setData(newData);
      } catch (e) {
        console.error(e);
      }

      loaded(true);
      firstTimeLoaded = false;

      if (
        (timeout == null || timeout == undefined) &&
        refreshTime !== null &&
        refreshTime !== undefined &&
        refreshTime > 0
      ) {
        timeout = setTimeout(() => getData(), refreshTime);
      }
    }
    getData();
  }, [currency, t("Live"), t("Day"), t("Week"), t("Month")]);

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div>
      <MaterialReactTable
        columns={columns}
        data={data ? data : []}
        enableHiding={false}
        enableFilters={false}
        enableSorting={false}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableTopToolbar={false}
        enablePagination={false}
        enableColumnActions={false}
        enableBottomToolbar={false}
        enableTableHead={false}
      />
    </div>
  );
};

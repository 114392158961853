import React from "react";
import "./InfoBox.css";

export const InfoBox: React.FC<{
  title: string | React.ReactElement | React.ReactElement[];
  value: string;
  loaded?: boolean;
  icon: React.ReactElement;
  style: React.CSSProperties;
  onClickIcon?: any;
}> = ({ title, loaded = true, value, icon, style, onClickIcon }) => {
  return (
    <div className="info-box">
      <span
        onClick={(e) => onClickIcon(e)}
        className="info-box-icon"
        style={style}
      >
        {icon}
      </span>
      <div className="info-box-content">
        <span className="info-box-text">{title}</span>
        <span className="info-box-number">{value}</span>
      </div>
      {!loaded ? (
        <div className="overlay">
          <div className="fa-1x">
            <i className="fas fa-spinner fa-pulse fa-spin fa-3x" />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

import moment from "moment";
import { CurrencyEnum } from "../models/CurrencyEnum";

export default class UtilService {
  static getCurrencyToKeyPart(currencyEnum: CurrencyEnum): string {
    return currencyEnum === CurrencyEnum.USDT ? "Usdt" : "Eur";
  }

  static datasetKeyProvider() {
    return (Math.random() + 1).toString(36).substring(7);
  }

  static transformInterval(value: string): {
    from: string | null;
    to: string | null;
  } {
    let from = moment();
    let to = moment();

    switch (value) {
      case "week":
        return {
          from: from.add(-7, "days").format("YYYY-MM-DD"),
          to: to.format("YYYY-MM-DD"),
        };
      case "month":
        return {
          from: from.add(-1, "months").format("YYYY-MM-DD"),
          to: to.format("YYYY-MM-DD"),
        };
      case "3month":
        return {
          from: from.add(-3, "months").format("YYYY-MM-DD"),
          to: to.format("YYYY-MM-DD"),
        };
      case "6month":
        return {
          from: from.add(-6, "months").format("YYYY-MM-DD"),
          to: to.format("YYYY-MM-DD"),
        };
      case "year":
        return {
          from: from.add(-1, "years").format("YYYY-MM-DD"),
          to: to.format("YYYY-MM-DD"),
        };
    }
    return {
      from: null,
      to: null,
    };
  }
}

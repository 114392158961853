import axios from "axios";
import * as tunnel from "tunnel";
import dotenv from "dotenv";
import AuthService from "./services/AuthService";

dotenv.config();

export const PROXY_HOST = process.env.REACT_APP_PROXY_HOST!;
export const PROXY_PORT = parseInt(process.env.REACT_APP_PROXY_PORT!);
export const LOCALSTORAGE_USER = process.env.REACT_APP_LOCALSTORAGE_USER!;
export const AUTH_API_BASE_URL = process.env.REACT_APP_AUTH_API_BASE_URL!;
export const DATA_API_BASE_URL = process.env.REACT_APP_DATA_API_BASE_URL!;

const agent = tunnel.httpsOverHttp({
  proxy: {
    host: PROXY_HOST,
    port: PROXY_PORT,
  },
});

axios.defaults.withCredentials = true;
export const cveuDefaultApi =
  process.env.PROXY_ENABLE != null && process.env.REACT_APP_PROXY_ENABLE === "1"
    ? axios.create({
        httpsAgent: agent,
      })
    : axios.create({
        baseURL: DATA_API_BASE_URL,
        withCredentials: true,
      });

export const cveuAuthApi = axios.create({
  baseURL: AUTH_API_BASE_URL,
});

if (
  process.env.REACT_APP_IGNORE_AUTH != null &&
  process.env.REACT_APP_IGNORE_AUTH === "1"
) {
  localStorage.setItem(LOCALSTORAGE_USER, JSON.stringify("{}"));
}

async function autorefresh() {
  if (AuthService.getUser()) await AuthService.refreshToken();
  setTimeout(autorefresh, parseInt(process.env.REACT_APP_AUTO_REFRESH_TOKEN!));
}
if (process.env.REACT_APP_AUTO_REFRESH_TOKEN)
  setTimeout(autorefresh, parseInt(process.env.REACT_APP_AUTO_REFRESH_TOKEN!));

import React, { useState } from "react";

import { InvestValueComponent } from "./components/InvestValueComponent";
import { ActualValueComponent } from "./components/ActualValueComponent";
import { Box } from "../../components/Box";
import { Col, Row } from "react-bootstrap";
import { ProfitPercentTableComponent } from "./components/ProfitPercentTableComponent";
import { ProfitCurrencyTableComponent } from "./components/ProfitCurrencyTableComponent";
import { PortfolioPieChartComponent } from "./components/PortfolioPieChartComponent";
import { ProfitValueLineChartComponent } from "./components/ProfitValueLineChartComponent";
import { PortfolioValueLineChartComponent } from "./components/PortfolioValueLineChartComponent";
import { CurrencyEnum } from "../../models/CurrencyEnum";
import { useTranslation } from "react-i18next";
import {
  FaExpand
} from "react-icons/fa";
import { LoadingOverlay } from "../../components/LoadingOverlay";

export const Dashboard: React.FC<{
  currency: CurrencyEnum;
}> = ({ currency }) => {
  const [profitPercentTableLoaded, setProfitPercentTableLoaded] =
    useState<boolean>(false);
  const [profitCurrencyTableLoaded, setProfitCurrencyTableLoaded] =
    useState<boolean>(false);
  const [portfolioPieChartLoaded, setPortfolioPieChartLoaded] =
    useState<boolean>(false);
  const [portfolioPieChartPopupLoaded, setPortfolioPieChartPopupLoaded] =
    useState<boolean>(false);
  const [portfolioValueLineChartLoaded, setPortfolioValueLineChartLoaded] =
    useState<boolean>(false);
  const [
    portfolioValueLineChartPopupLoaded,
    setPortfolioValueLineChartPopupLoaded,
  ] = useState<boolean>(false);
  const [profitValueLineChartLoaded, setProfitValueLineChartLoaded] =
    useState<boolean>(false);
  const [profitValueLineChartPopupLoaded, setProfitValueLineChartPopupLoaded] =
    useState<boolean>(false);
  const [profitValueLineChartData, setProfitValueLineChartData] = useState<any>(
    {}
  );
  const [portfolioPieChartData, setPortfolioPieChartData] = useState<any>({});
  const [portfolioValueLineData, setPortfolioValueLineData] = useState<any>({});

  const [profitValueLineChartInterval, setProfitValueLineChartInterval] =
    useState<string>("week");
  const [portfolioValueLineInterval, setPortfolioValueLineInterval] =
    useState<string>("week");

  const { t } = useTranslation();

  return (
    <div className="content-wrapper">
      <section className="content">
        <div className="container-fluid">
          <Row>
            <Col md={6}>
              <ActualValueComponent
                onlyFirstTimeShowLoading={false}
                title={t("Actual_value") + " [" + currency + "]"}
                currency={currency}
              />
            </Col>
            <Col md={6}>
              <InvestValueComponent
                onlyFirstTimeShowLoading={false}
                title={t("Invest_value") + " [" + currency + "]"}
                currency={currency}
              />
            </Col>
            <Col md={4}>
              <Box
                loaded={profitPercentTableLoaded}
                title={
                  <div>
                    <i className="fas fa-table mr-1" />
                    {t("Profit")} [%]
                  </div>
                }
                isTable={true}
              >
                <div className="box-overflow" style={{ minHeight: "212px" }}>
                  <ProfitPercentTableComponent
                    onlyFirstTimeShowLoading={true}
                    refreshTime={10000}
                    loaded={(state: boolean) => {
                      setProfitPercentTableLoaded(state);
                    }}
                  />
                </div>
              </Box>
            </Col>
            <Col md={4}>
              <Box
                loaded={profitCurrencyTableLoaded}
                title={
                  <div>
                    <i className="fas fa-table mr-1" />
                    {t("Profit")} [{currency}]
                  </div>
                }
                isTable={true}
              >
                <div className="box-overflow" style={{ minHeight: "212px" }}>
                  <ProfitCurrencyTableComponent
                    onlyFirstTimeShowLoading={true}
                    refreshTime={10000}
                    loaded={(state: boolean) => {
                      setProfitCurrencyTableLoaded(state);
                    }}
                    currency={currency}
                  />
                </div>
              </Box>
            </Col>
            <Col md={4}>
              <div
                className="modal fade"
                id="portfolioPieChart"
                tabIndex={-1}
                role="dialog"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">
                        <i className="fas fa-chart-pie mr-1" />
                        {t("Portfolio")} [$]
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body" style={{ minHeight: "60vh" }}>
                      <PortfolioPieChartComponent
                        onlyFirstTimeShowLoading={true}
                        loaded={(state: boolean) => {
                          setPortfolioPieChartPopupLoaded(state);
                        }}
                        loadDataOn={false}
                        dataIn={portfolioPieChartData}
                      />
                      <LoadingOverlay
                        loaded={portfolioPieChartPopupLoaded}
                      ></LoadingOverlay>
                    </div>
                  </div>
                </div>
              </div>
              <Box
                loaded={portfolioPieChartLoaded}
                title={
                  <div>
                    <i className="fas fa-chart-pie mr-1" />
                    {t("Portfolio")} [$]
                  </div>
                }
                actions={
                  <FaExpand
                    data-toggle="modal"
                    data-target="#portfolioPieChart"
                  />
                }
              >
                <div className="box-overflow" style={{ minHeight: "209px" }}>
                  <PortfolioPieChartComponent
                    onlyFirstTimeShowLoading={true}
                    loaded={(state: boolean) => {
                      setPortfolioPieChartLoaded(state);
                    }}
                    loadDataOn={true}
                    onChangeData={(data: any) => {
                      setPortfolioPieChartData(data);
                    }}
                  />
                </div>
              </Box>
            </Col>
            <Col md={6}>
              <div
                className="modal fade"
                id="portfolioValueLineChart"
                tabIndex={-1}
                role="dialog"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog"
                  role="document"
                  style={{ minHeight: "60vh", minWidth: "60vw" }}
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">
                        <i className="fas fa-chart-line  mr-1"></i>
                        {t("Portfolio_value")} [{currency}]
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div
                      className="modal-body"
                      style={{ minHeight: "60vh", minWidth: "60vw" }}
                    >
                      <PortfolioValueLineChartComponent
                        currency={currency}
                        onlyFirstTimeShowLoading={true}
                        loadDataOn={false}
                        dataIn={portfolioValueLineData}
                        loaded={(state: boolean) => {
                          setPortfolioValueLineChartPopupLoaded(state);
                        }}
                        intervalIn={portfolioValueLineInterval}
                        onChangeInterval={(interval: string) =>
                          setPortfolioValueLineInterval(interval)
                        }
                        minHeight={"60vh"}
                      />
                      <LoadingOverlay
                        loaded={portfolioValueLineChartPopupLoaded}
                      ></LoadingOverlay>
                    </div>
                  </div>
                </div>
              </div>
              <Box
                loaded={portfolioValueLineChartLoaded}
                title={
                  <div>
                    <i className="fas fa-chart-line  mr-1"></i>
                    {t("Portfolio_value")} [{currency}]
                  </div>
                }
                actions={
                  <FaExpand
                    data-toggle="modal"
                    data-target="#portfolioValueLineChart"
                  />
                }
              >
                <div className="box-overflow" style={{ minHeight: "180px" }}>
                  <PortfolioValueLineChartComponent
                    currency={currency}
                    onlyFirstTimeShowLoading={true}
                    loaded={(state: boolean) => {
                      setPortfolioValueLineChartLoaded(state);
                    }}
                    loadDataOn={true}
                    onChangeData={(data: any) => {
                      setPortfolioValueLineData(data);
                    }}
                    intervalIn={portfolioValueLineInterval}
                    onChangeInterval={(interval: string) =>
                      setPortfolioValueLineInterval(interval)
                    }
                  />
                </div>
              </Box>
            </Col>
            <Col md={6}>
              <div
                className="modal fade"
                id="profitValueLineChart"
                tabIndex={-1}
                role="dialog"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog"
                  role="document"
                  style={{ minHeight: "60vh", minWidth: "60vw" }}
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">
                        <i className="fas fa-chart-line  mr-1"></i>
                        {t("Profit_value")} [{currency}]
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div
                      className="modal-body"
                      style={{ minHeight: "60vh", minWidth: "60vw" }}
                    >
                      <ProfitValueLineChartComponent
                        currency={currency}
                        onlyFirstTimeShowLoading={true}
                        loadDataOn={false}
                        dataIn={profitValueLineChartData}
                        loaded={(state: boolean) => {
                          setProfitValueLineChartPopupLoaded(state);
                        }}
                        intervalIn={profitValueLineChartInterval}
                        onChangeInterval={(interval: string) =>
                          setProfitValueLineChartInterval(interval)
                        }
                        minHeight={"60vh"}
                      />
                      <LoadingOverlay
                        loaded={profitValueLineChartPopupLoaded}
                      ></LoadingOverlay>
                    </div>
                  </div>
                </div>
              </div>
              <Box
                loaded={profitValueLineChartLoaded}
                title={
                  <div>
                    <i className="fas fa-chart-line  mr-1"></i>
                    {t("Profit_value")} [{currency}]
                  </div>
                }
                actions={
                  <FaExpand
                    data-toggle="modal"
                    data-target="#profitValueLineChart"
                  />
                }
              >
                <div className="box-overflow" style={{ minHeight: "180px" }}>
                  <ProfitValueLineChartComponent
                    currency={currency}
                    onlyFirstTimeShowLoading={true}
                    loaded={(state: boolean) => {
                      setProfitValueLineChartLoaded(state);
                    }}
                    loadDataOn={true}
                    onChangeData={(data: any) => {
                      setProfitValueLineChartData(data);
                    }}
                    intervalIn={profitValueLineChartInterval}
                    onChangeInterval={(interval: string) =>
                      setProfitValueLineChartInterval(interval)
                    }
                  />
                </div>
              </Box>
            </Col>
          </Row>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;

import React, { useEffect, useMemo, useState } from "react";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { ODepositsTable } from "../../../services/DashboardService";
import { useTranslation } from "react-i18next";
import dateFormat from "dateformat";
import ProfileService, { ODetailTable } from "../../../services/ProfileService";

export const DetailTableComponent: React.FC<{
  loaded: any;
  onlyFirstTimeShowLoading: boolean;
  refreshTime?: number;
}> = ({ loaded, onlyFirstTimeShowLoading, refreshTime }) => {
  const { t } = useTranslation();
  let timeout: any = undefined;

  const [data, setData] = useState<{ name: string; value: string }[]>([]);

  let columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "name",
        header: "",
        size: 85,
      },
      {
        accessorKey: "value",
        header: "",
        size: 85,
      },
    ],
    []
  );

  useEffect(() => {
    let firstTimeLoaded = true;
    const profileService: ProfileService = new ProfileService();
    async function getData() {
      if (firstTimeLoaded || !onlyFirstTimeShowLoading) loaded(false);

      const lineValKeys: {
        key: string;
        text: string;
      }[] = [
        { key: "referallKey", text: t("Referal_key") },
        { key: "firstName", text: t("First_name") },
        { key: "lastName", text: t("Last_name") },
        { key: "email", text: t("Email") },
        { key: "mobile", text: t("Mobile") },
        { key: "birthDate", text: t("Birth_date") },
        { key: "address", text: t("Address") },
        { key: "city", text: t("City") },
        { key: "postcode", text: t("Postcode") },
        { key: "iban", text: t("Iban") },
        { key: "cardNumber", text: t("Card_number") },
      ];

      try {
        let _data = await profileService.getDetail();
        let newData: {
          name: string;
          value: string;
        }[] = [];

        for (let i = 0; i < lineValKeys.length; i++) {
          const lineValKey = lineValKeys[i];

          newData.push({
            name: lineValKey.text,
            value: _data[lineValKey.key as keyof ODetailTable].toString(),
          });
        }
        setData(newData);
      } catch (e) {
        console.error(e);
      }

      loaded(true);
      firstTimeLoaded = false;

      if (
        refreshTime !== null &&
        refreshTime !== undefined &&
        refreshTime > 0
      ) {
        timeout = setTimeout(() => getData(), refreshTime);
      }
    }

    getData();
  }, [
    t("Referal_key"),
    t("First_name"),
    t("Last_name"),
    t("Email"),
    t("Mobile"),
    t("Birth_date"),
    t("Address"),
    t("City"),
    t("Postcode"),
    t("Iban"),
    t("Card_number"),
  ]);

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div>
      <MaterialReactTable
        columns={columns}
        data={data ? data : []}
        enableHiding={false}
        enableFilters={false}
        enableSorting={false}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableTopToolbar={false}
        enablePagination={false}
        enableColumnActions={false}
        enableBottomToolbar={false}
        enableTableHead={false}
      />
    </div>
  );
};

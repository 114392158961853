import React from "react";

export const LoadingOverlay: React.FC<{
  loaded: boolean;
}> = ({ loaded = true }) => {
  return !loaded ? (
    <div className="overlay">
      <div className="fa-1x">
        <i className="fas fa-spinner fa-pulse fa-spin fa-3x" />
      </div>
    </div>
  ) : (
    <div></div>
  );
};

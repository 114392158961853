import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import AuthService from "../../services/AuthService";
import * as Yup from "yup";
import "./Login.css";
import logo from "../../assets/img/LOGO_CVEU.png";
import { FaFacebook, FaLock } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Login: React.FC<{}> = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const [isInvalidCredentials, setIsInvalidCredentials] = useState<any>(false);
  const [isFirstLogin, setIsFirstLogin] = useState<any>(false);

  return (
    <div style={{ width: "100%", height: "100%", backgroundColor: "black" }}>
      <video autoPlay muted loop id="myVideo">
        <source
          src="https://www.cveu.eu/wp-content/uploads/2022/05/video_reduced_cut.mp4"
          type="video/mp4"
        ></source>
      </video>
      <div className="login-page" style={{ minHeight: "466px" }}>
        <div className="login-box">
          <div className="card card-outline card-primary">
            <div className="card-header text-center">
              <img alt="logo" src={logo} style={{ maxHeight: "70px" }} />
            </div>
            <div className="card-body">
              <p className="login-box-msg">{t("Login_to_my_CVEU")}</p>
              {isFirstLogin ? (
                <p className="login-box-msg">
                  {t("First_time_reset_password")}
                </p>
              ) : (
                ""
              )}
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                onSubmit={async (values) => {
                  AuthService.login(values.email, values.password)
                    .then((x) => {
                      navigate("/dashboard");
                    })
                    .catch((e: any) => {
                      if (e.response.data.errorCode === "FL") {
                        setIsInvalidCredentials(false);
                        setIsFirstLogin(true);
                      } else if (e.response.data.errorCode === "IC") {
                        setIsInvalidCredentials(true);
                        setIsFirstLogin(false);
                      } else {
                        setIsInvalidCredentials(false);
                        setIsFirstLogin(false);
                        console.error(e);
                      }
                    });
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email(t("Email_is_invalid"))
                    .required(t("Email_is_required")),
                  password: Yup.string().required(t("Password_is_required")),
                })}
                render={({ errors, status, touched }) => (
                  <Form>
                    <div className="input-group mb-3">
                      <Field
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        id="email"
                        name="email"
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <FaEnvelope></FaEnvelope>
                        </div>
                      </div>

                      <ErrorMessage
                        name="email"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="input-group mb-3">
                      <Field
                        type="password"
                        className="form-control"
                        placeholder={t("Password")}
                        id="password"
                        name="password"
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <FaLock></FaLock>
                        </div>
                      </div>

                      <ErrorMessage
                        name="password"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="forgot-button">
                      <a href="/forgot-password">Zabudnuté heslo</a>
                    </div>
                    <div className="btn-group">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                      >
                        {t("Sign_in")}
                      </button>

                      {isInvalidCredentials ? (
                        <div className="invalid-feedback">
                          {t("Invalid_credentials")}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="social-group-text">
                      {t("Join_to_with_us_at_social_sites")}
                    </div>
                    <div className="social-group">
                      <div className="social-group-box">
                        <a
                          className="social-box-item"
                          href="https://www.facebook.com/cveu.eu"
                        >
                          <FaFacebook size={30} />
                        </a>
                        <a
                          className="social-box-item"
                          href="https://www.instagram.com/cveu.eu"
                        >
                          <FaTwitter size={30} />
                        </a>
                        <a
                          className="social-box-item"
                          href="https://www.linkedin.com/in/dusan-patasi-711084213/"
                        >
                          <FaLinkedin size={30} />
                        </a>
                        <a
                          className="social-box-item"
                          href="https://www.instagram.com/cveu.eu/"
                        >
                          <FaInstagram size={30} />
                        </a>
                      </div>
                    </div>
                  </Form>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { ChartOptions } from "chart.js";
import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import DashboardService from "../../../services/DashboardService";
import { useTranslation } from "react-i18next";
import UtilService from "../../../services/UtilService";
import { json } from "react-router-dom";

export const PortfolioPieChartComponent: React.FC<{
  loaded: any;
  onlyFirstTimeShowLoading: boolean;
  refreshTime?: number;
  onChangeData?: any;
  loadDataOn: boolean;
  dataIn?: any;
}> = ({
  loaded,
  onlyFirstTimeShowLoading,
  refreshTime,
  onChangeData,
  loadDataOn,
  dataIn,
}) => {
  const { t } = useTranslation();
  let timeout: any = undefined;

  const [data, setData] = useState<any>({
    labels: [],
    datasets: [
      {
        backgroundColor: ["rgba(250, 65, 65, 0.8)", "rgba(220,220,220,0.8)"],
        data: [],
      },
    ],
  });

  const options: ChartOptions = {
    legend: {
      display: true,
    },
    // String - A leg,end template
    // Boolean - whether to maintain the starting aspect ratio or not when responsive, if set to false, will take up entire container
    maintainAspectRatio: false,
    // Boolean - whether to make the chart responsive to window resizing
    responsive: true,
    tooltips: {
      callbacks: {
        label(tooltipItem, data) {
          return " " + data.datasets![0].data?.at(tooltipItem.index!) + " $";
        },
        title(item, data) {
          return data.labels![item[0].index!].toString();
        },
      },
    },
  };

  useEffect(() => {
    let firstTimeLoaded = true;
    const dashboardService: DashboardService = new DashboardService();

    async function getData() {
      if (firstTimeLoaded || !onlyFirstTimeShowLoading) loaded(false);

      try {
        const _data = await dashboardService.getPortfolioPieChart();
        let chartData = data;
        chartData.labels = [t("Futures"), t("Reserve")];
        chartData.datasets![0].data = [
          _data.futuresCapitalUsdt.toFixed(2),
          _data.reserveCapitalUsdt.toFixed(2),
        ];
        setData(chartData);
        onChangeData(chartData);
      } catch (e) {
        console.error(e);
      }

      loaded(true);
      firstTimeLoaded = false;

      if (
        (timeout == null || timeout == undefined) &&
        refreshTime !== null &&
        refreshTime !== undefined &&
        refreshTime > 0
      ) {
        timeout = setTimeout(() => getData(), refreshTime);
      }
    }

    if (loadDataOn) {
      getData();
    } else {
      if (firstTimeLoaded || !onlyFirstTimeShowLoading) loaded(false);
      setData(dataIn);
      loaded(true);
    }
  }, [t("Futures"), t("Reserve"), dataIn]);

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Pie
      data={data}
      options={options}
      height={180}
      datasetKeyProvider={() => {
        return UtilService.datasetKeyProvider();
      }}
    />
  );
};

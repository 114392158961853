import React, { useEffect, useState } from "react";
import { InfoBox } from "../../../components/InfoBox";
import { CurrencyEnum } from "../../../models/CurrencyEnum";
import DashboardService from "../../../services/DashboardService";
import UtilService from "../../../services/UtilService";
import QueryStatsIcon from "@mui/icons-material/QueryStats";

export const ActualValueComponent: React.FC<{
  title: string;
  onlyFirstTimeShowLoading: boolean;
  refreshTime?: number;
  currency: CurrencyEnum;
}> = ({ refreshTime, title, onlyFirstTimeShowLoading, currency }) => {
  let timeout: any = undefined;

  const [data, setData] = useState<string>("");
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    let firstTimeLoaded = true;
    const dashboardService: DashboardService = new DashboardService();

    async function getData() {
      if (firstTimeLoaded || !onlyFirstTimeShowLoading) setLoaded(false);

      try {
        let _data = (await dashboardService.getActualValue()) as any;
        const showValue = parseFloat(
          String(_data["capital" + UtilService.getCurrencyToKeyPart(currency)])
        ).toFixed(2);

        setData(showValue);
      } catch (e) {
        console.error(e);
      }

      setLoaded(true);
      firstTimeLoaded = false;

      if (
        (timeout == null || timeout == undefined) &&
        refreshTime !== null &&
        refreshTime !== undefined &&
        refreshTime > 0
      ) {
        timeout = setTimeout(() => getData(), refreshTime);
      }
    }

    getData();
  }, [currency]);

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <InfoBox
      title={title}
      value={data}
      icon={<QueryStatsIcon fontSize="large"></QueryStatsIcon>}
      style={{ backgroundColor: "rgba(250, 65, 65, 0.8)", color: "white" }}
      loaded={loaded}
    ></InfoBox>
  );
};
